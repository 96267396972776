.SingleDatePicker {
  width: 100%;

  .SingleDatePickerInput {
    width: 100%;
  }

  .DateInput__small {
    width: 100%;
  }

  .DateInput_input {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
  }

  .DateInput_input__focused {
    color: #5c6873;
    background-color: #fff;
    border-color: #d0e4b4;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(150, 194, 86, 0.25);
    border-bottom: unset;
  }

  .SingleDatePicker_picker {
    z-index: 100;
  }
}

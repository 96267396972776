.react-bootstrap-table-pagination {
  > div {
    display: none;
  }

  .react-bootstrap-table-pagination-list {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    flex: 0 0 100%;
  }

  .header-text {
    vertical-align: middle;
  }
}

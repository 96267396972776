// Here you can add other styles
.sidebar .nav-link {
  &.active {
    color: #fff;
    background: #96c256;

    .nav-icon {
      color: #fff;
    }
  }

  &:hover {
    background: #3a4248;
  }
}

.test {
  background: #88c441;
}

.ErrorMessage {
  color: #dc3545;
}

.hasError {
  label {
    color: #dc3545;
  }

  .SingleDatePicker .DateInput_input,
  .form-control {
    border: 1px solid #dc3545;
    border-radius: 0.25rem;
  }

  &.input-group {
    border: 1px solid #dc3545;
    border-radius: 0.25rem;
  }

  input {
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }
}

.react-bootstrap-table-responsive {
  overflow-x: auto;
}

.main .container-fluid {
  padding: 30px;
}

label:not(.RadioButtonLabel) {
  font-weight: bold;
}
